.loader {
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: #686831;
}

@keyframes wave {
  0% {opacity: 0; transform: translateY(15px)}
  50% {opacity: 1; transform: translateY(0)}
  100% {opacity: 0; transform: translateY(-15px)}
}
.loader ul {
  margin: 0 0 10px 0;
  padding: 0;
  list-style: none;
  width: 60px;
  text-align: center;
  animation: wave 2.5s infinite linear;
  animation-fill-mode: forwards;
}
.loader li {
  display: inline-block;
  background: #FAF1D9;
  height: 30px;
  width: 9px;
  border-radius: 0 100%;
  transform: rotate(12deg);
}
.cup {
  background: #F2E3B4;
  width: 60px;
  height: 54px;
  border-radius: 0 0 50% 50%;
  position: relative;
}
.cup:before {
  content: "";
  position: absolute;
  width: 66px;
  height: 20px;
  border-radius: 50%;
  background: inherit;
  left: -3px;
  top: -10px;
}
.cup:after {
  content: "";
  position: absolute;
  width: 56px;
  height: 12px;
  border-radius: 50%;
  background: #3A2F28;
  left: 2px;
  top: -6px;
}
.loader span {
  background: #F2E3B4;
  width: 13px;
  height: 20px;
  position: absolute;
  right: -13px;
  top: 10px;
  border-radius: 0 50% 50% 0;
}
.loader span:before {
  content: "";
  position: absolute;
  top: 4px;
  left: 0;
  background: #BF7C2C;
  width: 10px;
  height: 12px;
  border-radius: 0 50% 50% 0;
}
.loader span:after {
  content: "";
  position: absolute;
  top: 40px;
  left: -50px;
  background: #F2E3B4;
  width: 40px;
  height: 8px;
  border-radius: 50%;
}