
/*.ModalPass {*/
/*  !*top: 40px;*!*/
/*  !*left: 40px;*!*/
/*  right: 40px;*/
/*  bottom: 40px;*/
/*  position: fixed;*/
/*  width: 400px;*/
/*  z-index: 1040;*/
/*  top: 30%;*/
/*  left: 30%;*/
/*  background-color: white;*/
/*  border: 1px solid rgba(0, 0, 0, 0.2);*/
/*  border-radius: 3px;*/
/*  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);*/
/*}*/
.ModalPass {
  position: absolute;
  /*inset: 10px;*/
  top: 50%;
  left: 40%;
  transform: translateY(-50%);
  border: 1px solid rgb(204, 204, 204);
  background: rgb(255, 255, 255);
  overflow: auto;
  border-radius: 4px;
  outline: none;
  padding: 20px;
}


.BackdropPass {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999999;
  background-color: rgba(13, 13, 13, 0.52);
  /*transform: translate(-50%, -50%);*/
}

.Title {
  font-weight: 500;
  font-size: 1.25rem;
}

.Header {
  border-bottom: 1px solid #e9ecef;
  display: flex;
  justify-content: space-between;
}

.ModalDescPass,
.ModalHeaderPass,
.ModalFooterPass {
  padding: 12px;
}

.CloseButtonPass {
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  border: none;
  background-color: white;
  opacity: 0.7;
  cursor: pointer;
}
.ButtonPass {
  cursor: pointer;
  font-size: 1rem;
  padding: 10px 12px;
  border-radius: 5px;
  border: none;
}

.ModalFooterPass {
  border-top: 1px solid #e9ecef;
  display: flex;
  justify-content: flex-end;
  gap: 8px;
}

.SecondaryButtonPass {
  color: #fff;
  background-color: #6c757d;
}
.PrimaryButtonPass {
  color: #fff;
  background-color: #007bff;
}
